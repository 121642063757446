/* eslint-disable @typescript-eslint/no-explicit-any */
export const toNumber = (value: string | number | null, defaultValue: number = 0): number =>
    value ? parseInt(value as string, 10) : defaultValue;

export const toCamelCase = (snakeCased: string) =>
    snakeCased.replace(/(_\w)/g, (match) => match.toUpperCase().substr(1));

export const processValue = (value: any) =>
    value === 1 || value === '1' ? true : value === 0 || value === '0' ? false : value;

export const processKey = (key: string, prefix: string = '') => {
    const processedKey = key.replace(prefix, '');

    return toCamelCase(processedKey);
};

export const camelCaseData = (data: any, prefix: string = '') =>
    Object.entries(data).reduce(
        (allData, [key, value]) => ({ ...allData, [processKey(key, prefix)]: processValue(value) }),
        {}
    );
