import { queryProvider, serviceQueries } from '@estee/elc-service-bus';

import { UserProfileRepository } from '~repositories/UserProfileRepository';
import { LoadUserProfileUseCase } from '~use-cases/LoadUserProfileUseCase';

export class ServiceBusController {
    private userProfileRepository: UserProfileRepository;

    constructor(userProfileRepository: UserProfileRepository) {
        this.userProfileRepository = userProfileRepository;
    }

    @queryProvider(serviceQueries.GET_USER_ACCOUNT_ID)
    public userId = async () => {
        await new LoadUserProfileUseCase().execute();

        return this.userProfileRepository?.userProfileId;
    };

    @queryProvider(serviceQueries.EMAIL)
    public email = async () => {
        await new LoadUserProfileUseCase().execute();

        return this.userProfileRepository?.emailAddress;
    };

    @queryProvider(serviceQueries.BASIC_USER_DETAILS)
    public basicUserDetails = async () => {
        await new LoadUserProfileUseCase().execute();

        return this.userProfileRepository?.basicUserDetails;
    };
}
