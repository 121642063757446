import { IAppConfig } from '@estee/elc-universal-utils';

type PerlgemId = string;

interface IPerlgemConfig {
    abbrev?: string;
    id: PerlgemId;
    literal?: string;
}

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
interface IBrandConfig extends IPerlgemConfig {}

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
interface ILanguageConfig extends IPerlgemConfig {}

interface IRegionConfig extends IPerlgemConfig {
    perlgem_region_id?: PerlgemId;
}

export interface IServiceAppConfig extends IAppConfig {
    pgApiTokens: {
        [key: string]: string;
    };
}

interface IBUIDConfig {
    brand: IBrandConfig;
    language: ILanguageConfig;
    region: IRegionConfig;
}

export interface IServiceAppConfigs extends IServiceAppConfig, IBUIDConfig {}

export const brandIdPath = 'brand.id';
export const languageIdPath = 'language.id';
export const regionIdPath = 'region.id';
interface IBUIDPathedConfig extends IBUIDConfig {
    [brandIdPath]?: IBrandConfig['id'];
    [languageIdPath]?: ILanguageConfig['id'];
    [regionIdPath]?: IRegionConfig['id'];
}

export interface IAppAndBUIDConfigs {
    appConfig: IServiceAppConfig;
    buidConfig: Partial<IBUIDPathedConfig>;
}

export interface IAppConfigWithRequestedBUIDKeys {
    appConfig: IServiceAppConfig;
    requestedBuidConfigs: string[];
}
