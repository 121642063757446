import { action, computed, observable } from 'mobx';

import { query, serviceQueries } from '@estee/elc-service-bus';

import { Config } from '~domain/entities/Config';
import {
    brandIdPath,
    languageIdPath,
    regionIdPath,
    IAppAndBUIDConfigs,
    IAppConfigWithRequestedBUIDKeys,
    IServiceAppConfigs
} from '~domain/entities/IConfig';

export class ConfigRepository {
    @observable private serviceConfig: Config;

    constructor(serviceConfig: Config) {
        this.serviceConfig = serviceConfig;
    }

    private modifyBuidPathedConfigs(buidConfig: IAppAndBUIDConfigs['buidConfig']) {
        const {
            [brandIdPath]: bId,
            [languageIdPath]: lId,
            [regionIdPath]: rId,
            ...remainingConfigs
        } = buidConfig;

        const serviceConfigs = <IServiceAppConfigs>{
            ...remainingConfigs,
            brand: { id: bId },
            language: { id: lId },
            region: { id: rId }
        };

        return serviceConfigs;
    }

    private async fetchBuidConfigs(requestedBuidConfigs: string[]) {
        const response = await query(serviceQueries.GET_CONFIG, requestedBuidConfigs);

        return response;
    }

    @action public async fetchConfigs({
        appConfig,
        requestedBuidConfigs
    }: IAppConfigWithRequestedBUIDKeys) {
        const buidConfigResponse = await this.fetchBuidConfigs(requestedBuidConfigs);
        const buidConfig = this.modifyBuidPathedConfigs(buidConfigResponse);

        this.serviceConfig.updateConfig({
            appConfig,
            buidConfig
        });
    }

    @computed
    public get config(): Config {
        return this.serviceConfig;
    }
}
