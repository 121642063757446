import { computed, observable, set } from 'mobx';

import { camelCaseData } from '~utils/ToCamelCase';

interface ILoyaltyInfo {
    loyalty_active_flag: string | null;
}

export interface IUserProfileResponse {
    accepted_privacy_policy: string;
    email_address: string;
    first_name: string;
    full_name?: string;
    last_name: string;
    loyalty_info?: ILoyaltyInfo;
    recognized: number;
    registered: number;
    signedin: number;
    user_id: string;
    fullsizepic: string;
    has_ontheway_orders: number;
    has_orders: number;
    has_replenishment_orders: number;
    loyalty_active_flag: string;
    profile_pic: string;
    thumbnailpic: string;
}

export interface IBasicUserDetails {
    firstName: string;
    lastName: string;
    emailAddress: string;
    hasLoyalty: boolean;
}

class UserProfile {
    @observable public acceptedPrivacyPolicy: boolean;
    @observable public emailAddress: string = '';
    @observable public firstName: string = '';
    @observable public fullName: string = '';
    @observable public hasOnthewayOrders: boolean;
    @observable public hasOrders: boolean;
    @observable public hasReplenishmentOrders: boolean;
    @observable public lastName: string = '';
    @observable public loyaltyActiveFlag: boolean;
    @observable public loyaltyInfo: Record<string, unknown>;
    @observable public recognized: boolean;
    @observable public registered: boolean;
    @observable public signedIn: boolean;
    @observable public userId: string = '';

    static fromJSON(userProfileApiResponse: IUserProfileResponse): UserProfile {
        const userProfile = new UserProfile();
        const modifiedData = camelCaseData(userProfileApiResponse);

        set(userProfile, modifiedData);

        return userProfile;
    }

    @computed public get name(): string {
        return this.fullName ? this.fullName : [this.firstName, this.lastName].join(' ');
    }

    @computed
    public get basicUserDetails(): IBasicUserDetails {
        return {
            emailAddress: this.emailAddress,
            firstName: this.firstName,
            lastName: this.lastName,
            hasLoyalty: this.loyaltyActiveFlag
        };
    }
}

export default UserProfile;
