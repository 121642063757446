import { action, computed, observable } from 'mobx';

import { UserProfileApiSdk } from '~api/UserProfileApiSdk';
import UserProfile, { IUserProfileResponse } from '~domain/entities/UserProfile';

import { AuthenticatedRepository } from './AuthenticatedRepository';

export class UserProfileRepository extends AuthenticatedRepository {
    private apiSdk: UserProfileApiSdk;
    @observable private userData: UserProfile;

    constructor(apiSdk: UserProfileApiSdk) {
        super();
        this.apiSdk = apiSdk;
    }

    @action public fetchUserProfile = async () => {
        return super.ensureData();
    };

    @action public fetchData = async () => {
        const data = <IUserProfileResponse>await this.apiSdk.getUserProfile();
        const userData = UserProfile.fromJSON(data);
        this.setUserData(userData);
    };

    @action public setUserData = (userData: UserProfile) => {
        this.userData = userData;
    };

    @computed
    public get userProfile() {
        return this.userData;
    }

    @computed
    public get userProfileId() {
        return this.userData?.userId;
    }

    @computed
    public get emailAddress() {
        return this.userData?.emailAddress;
    }

    @computed
    public get basicUserDetails() {
        return this.userData?.basicUserDetails;
    }
}
