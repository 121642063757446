import { diContainer, serviceNames } from '~setup/diContainer';
import { UserProfileRepository } from '~repositories/UserProfileRepository';

export class LoadUserProfileUseCase {
    private userProfileRepository: UserProfileRepository;

    constructor() {
        this.userProfileRepository = diContainer.get(serviceNames.userProfileRepository);
    }

    public async execute() {
        await this.userProfileRepository.fetchUserProfile();
    }
}
