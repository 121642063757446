import { HttpTransport } from '@estee/elc-http-transport';
import { root } from '@estee/elc-universal-utils';

import { IUserProfileResponse } from '~domain/entities/UserProfile';
import { ConfigRepository } from '~repositories/ConfigRepository';

type IPgApiConfigKeys = 'apiAuthToken' | 'brandId' | 'regionId';
type IPgApiConfigs = Pick<ConfigRepository['config'], IPgApiConfigKeys>;

interface IGlobalHeaders {
    Authorization: string;
    AuthorizationCredentials: string;
}

const BASE_API_PATH = '/rest/api/v1/ra';

export class UserProfileApiSdk {
    private httpTransport: HttpTransport;
    private apiAuthToken: ConfigRepository['config']['apiAuthToken'];
    private brand: ConfigRepository['config']['brandId'];
    private region: ConfigRepository['config']['regionId'];

    constructor(httpTransport: HttpTransport) {
        this.httpTransport = httpTransport;
    }

    public setConfigs({ apiAuthToken, brandId, regionId }: IPgApiConfigs) {
        this.brand = brandId;
        this.region = regionId;
        this.apiAuthToken = apiAuthToken;

        this.httpTransport.addGlobalHeaders(this.getGlobalHeaders());
    }

    private getAuthToken = (): string => this.apiAuthToken;

    private getGlobalHeaders = (): IGlobalHeaders => {
        const auth = btoa(this.getAuthToken());
        const AuthorizationCredentials = `Basic ${auth}`;
        const Authorization = AuthorizationCredentials;

        return {
            Authorization,
            AuthorizationCredentials
        };
    };

    // @todo: Extract this into an elc-perlgem-api package
    private getBaseUrl = (): string => `${root.location.protocol}//${root.location.hostname}`;

    public getDefaultParams = () => ({ brand: this.brand, region: this.region });

    public getUserProfile = async (): Promise<IUserProfileResponse> => {
        const params = this.getDefaultParams();
        const url = `${this.getBaseUrl()}${BASE_API_PATH}/user`;

        try {
            const userProfileResponse = await this.httpTransport.get<IUserProfileResponse>({
                url,
                params,
                options: {
                    credentials: 'include'
                }
            });

            return userProfileResponse.data;
        } catch (err) {
            return err;
        }
    };
}
