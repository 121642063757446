import { Service } from '@estee/elc-service';
import * as serviceViewsName from '@estee/elc-service-view-names';
import { ServiceInitializer } from '@estee/elc-universal-utils';

import { IServiceAppConfig } from '~domain/entities/IConfig';
import { UserProfileServiceStartedUseCase } from '~use-cases/UserProfileServiceStartedUseCase';

import { diContainer, serviceNames } from './diContainer';

const { name, version } = __serviceInfo__;

export class UserProfileService extends Service {
    constructor(serviceIntializer: ServiceInitializer) {
        super(name, version, serviceIntializer);

        this.registerView(
            serviceViewsName.USER_PROFILE_WELCOME,
            () =>
                import(
                    /* webpackChunkName: 'UserProfileWelcome' */
                    '../exported/user-profile-welcome/UserProfileWelcome'
                ),
            () => diContainer.get(serviceNames.userProfileWelcomeViewController)
        );

        this.registerView(
            serviceViewsName.USER_PROFILE_CARD,
            () =>
                import(
                    /* webpackChunkName: 'UserProfileCard' */
                    '../exported/user-profile-card/UserProfileCard'
                ),
            () => diContainer.get(serviceNames.userProfileCardViewController)
        );
    }

    public async start(appConfig: IServiceAppConfig) {
        const service = await super.start(appConfig);
        const requestedBuidConfigs = ['brand.id', 'language.id', 'region.id'];

        const useCase = new UserProfileServiceStartedUseCase({
            appConfig,
            requestedBuidConfigs
        });

        await useCase.execute();

        return service;
    }
}
