(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("HypoContainer"), require("HttpTransport"), require("mobx"), require("ElcServiceBus"), require("react"), require("ELCLogger"), require("mobx-react"), require("styled-components"));
	else if(typeof define === 'function' && define.amd)
		define(["HypoContainer", "HttpTransport", "mobx", "ElcServiceBus", "react", "ELCLogger", "mobx-react", "styled-components"], factory);
	else if(typeof exports === 'object')
		exports["pg-service-user-profile"] = factory(require("HypoContainer"), require("HttpTransport"), require("mobx"), require("ElcServiceBus"), require("react"), require("ELCLogger"), require("mobx-react"), require("styled-components"));
	else
		root["pg-service-user-profile"] = factory(root["ElcRuntime"]["HypoContainer"], root["ElcRuntime"]["HttpTransport"], root["ElcRuntime"]["mobx"], root["ElcServiceBus"], root["ElcRuntime"]["React"], root["ElcRuntime"]["ELCLogger"], root["ElcRuntime"]["mobxReact"], root["ElcRuntime"]["styled5"]);
})(self, (__WEBPACK_EXTERNAL_MODULE_hypo_container__, __WEBPACK_EXTERNAL_MODULE__estee_elc_http_transport__, __WEBPACK_EXTERNAL_MODULE_mobx__, __WEBPACK_EXTERNAL_MODULE__estee_elc_service_bus__, __WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE__estee_elc_logging__, __WEBPACK_EXTERNAL_MODULE_mobx_react__, __WEBPACK_EXTERNAL_MODULE_styled_components__) => {
return 