import { action, computed, observable } from 'mobx';

import { IServiceAppConfig, IServiceAppConfigs, IAppAndBUIDConfigs } from './IConfig';

export class Config {
    @observable public config: IServiceAppConfigs;

    constructor(appConfig: IServiceAppConfig) {
        this.updateConfig({ appConfig, buidConfig: {} });
    }

    @action
    public updateConfig(config: IAppAndBUIDConfigs) {
        this.config = {
            ...this.config,
            ...config?.appConfig,
            ...config?.buidConfig
        };
    }

    @computed
    public get brandId(): string {
        return this.config?.brand?.id;
    }

    @computed
    public get regionId(): string {
        return this.config?.region?.id;
    }

    @computed
    public get languageId(): string {
        return this.config?.language?.id;
    }

    @computed
    public get apiAuthToken(): string {
        return this.config?.pgApiTokens?.userProfile;
    }
}
