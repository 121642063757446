import { action, computed } from 'mobx';

import { ViewController } from '@estee/elc-service';

import { UserProfileRepository } from '~repositories/UserProfileRepository';
import { LoadUserProfileUseCase } from '~use-cases/LoadUserProfileUseCase';

class UserProfileWelcomeController extends ViewController {
    private userProfileRepository: UserProfileRepository;

    constructor(userProfileRepository: UserProfileRepository) {
        super();
        this.userProfileRepository = userProfileRepository;
    }

    @action
    public loaded = async () => {
        this.isLoading = true;
        await new LoadUserProfileUseCase().execute();
        this.isLoading = false;
    };

    @computed
    public get data() {
        return {
            userProfile: this.userProfileRepository.userProfile
        };
    }
}

export default UserProfileWelcomeController;
