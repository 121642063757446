import { action, computed, observable } from 'mobx';

import { ViewController } from '@estee/elc-service';

import UserProfile from '~domain/entities/UserProfile';
import { UserProfileRepository } from '~repositories/UserProfileRepository';
import { LoadUserProfileUseCase } from '~use-cases/LoadUserProfileUseCase';

export interface IUserProfileCardData {
    cardDetails: Pick<UserProfile, 'firstName' | 'emailAddress'>;
}

class UserProfileCardController extends ViewController {
    @observable private userProfileRepository: UserProfileRepository;

    constructor(userProfileRepository: UserProfileRepository) {
        super();
        this.userProfileRepository = userProfileRepository;
    }

    @action
    public loaded = async () => {
        this.isLoading = true;
        await new LoadUserProfileUseCase().execute();
        this.isLoading = false;
    };

    @computed
    public get data(): IUserProfileCardData {
        return {
            cardDetails: this.userProfileRepository?.userProfile?.basicUserDetails || {}
        };
    }
}

export default UserProfileCardController;
