import { Container } from 'hypo-container';

import { HttpTransport } from '@estee/elc-http-transport';
import { getDecorators } from '@estee/elc-service';

import { UserProfileApiSdk } from '~api/UserProfileApiSdk';
import { Config } from '~domain/entities/Config';
import UserProfile from '~domain/entities/UserProfile';
import UserProfileCardController from '~exported/user-profile-card/UserProfileCardController';
import UserProfileWelcomeController from '~exported/user-profile-welcome/UserProfileWelcomeController';
import { ConfigRepository } from '~repositories/ConfigRepository';
import { UserProfileRepository } from '~repositories/UserProfileRepository';

import { ServiceBusController } from './ServiceBusController';
import { UserProfileService } from './UserProfileService';

export const diContainer = new Container();
export const serviceNames = {
    config: 'CONFIG',
    configRepository: 'CONFIG_REPOSITORY',
    httpTransport: 'HTTP_TRANSPORT',
    serviceBusController: 'SERVICE_BUS_CONTROLLER',
    userProfile: 'USER_PROFILE',
    userProfileApiSdk: 'USER_PROFILE_API_SDK',
    userProfileCardViewController: 'USER_PROFILE_CARD_VIEW_CONTROLLER',
    userProfileRepository: 'USER_PROFILE_REPOSITORY',
    userProfileService: 'USER_PROFILE_SERVICE',
    userProfileWelcomeViewController: 'USER_PROFILE_WELCOME_VIEW_CONTROLLER'
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setConfig = (configJSON: any) => {
    diContainer.config = configJSON;
};

diContainer
    .register(serviceNames.config, (c) => {
        const appConfig = c.config;

        return new Config(appConfig);
    })
    .register(serviceNames.userProfileService, (c) => {
        const service = new UserProfileService((config) => {
            setConfig(config);
            const serviceBus = c.get(serviceNames.serviceBusController);

            return serviceBus;
        });

        return service;
    })
    .register(serviceNames.httpTransport, () => new HttpTransport())
    .register(serviceNames.userProfile, () => new UserProfile())
    .register(serviceNames.configRepository, (c) => {
        const entity = c.get(serviceNames.config);

        return new ConfigRepository(entity);
    })
    .register(serviceNames.userProfileRepository, (c) => {
        const api = c.get(serviceNames.userProfileApiSdk);

        return new UserProfileRepository(api);
    })
    .register(serviceNames.userProfileWelcomeViewController, (c) => {
        const repository = c.get(serviceNames.userProfileRepository);

        return new UserProfileWelcomeController(repository);
    })
    .register(serviceNames.userProfileCardViewController, (c) => {
        const repository = c.get(serviceNames.userProfileRepository);

        return new UserProfileCardController(repository);
    })
    .register(serviceNames.serviceBusController, (c) => {
        const repository = c.get(serviceNames.userProfileRepository);

        return new ServiceBusController(repository);
    })
    .register(serviceNames.userProfileApiSdk, (c) => {
        const transport = c.get(serviceNames.httpTransport);

        return new UserProfileApiSdk(transport);
    });

const { lazyInject } = getDecorators(diContainer);

export { lazyInject };
